import React from 'react'
import { connect } from 'react-redux'
import { useRouter } from 'next/router'
import { getVehicleParts } from 'SRC/modules/vehicleParts/list/selectors'
import { getPagination } from 'SRC/modules/vehicleParts/list/selectors'
import { Links } from 'SRC/modules/common/components/Pagination'
import PropTypes from 'prop-types'

const VehicleParts = ({ paginationParams, products }) => {
  const router = useRouter()

  const group = router.query?.group
  let filterValuesUrl = router?.query?.['0']

  const textFilter = router?.query?.['text-filter']

  if (filterValuesUrl) filterValuesUrl = filterValuesUrl.split('/').filter(item => !item.startsWith('strana-')).join('/')

  const pageCount = Number(paginationParams.totalPages)
  if (pageCount === 0) return null

  const currentPage = Number(paginationParams.page)
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === pageCount
  const baseUrl = { href: { pathname: '/vehicleParts', query: { '0': '' } }, as: '/novi-djelovi' }

  if (group && group !== 'svi-djelovi') {
    baseUrl.href.query.group = group
    baseUrl.as += `/${group}`
  }

  if (textFilter) baseUrl.href.query['text-filter'] = textFilter

  if (filterValuesUrl) {
    baseUrl.href.query['0'] = filterValuesUrl
    baseUrl.as += `/${filterValuesUrl}`
  }

  console.log('VehicleParts pagination baseUrl', baseUrl)

  const prevPageNumber = currentPage === 1 ? currentPage : currentPage - 1
  const prevPageAs = prevPageNumber === 1 ? '' : `/strana-${prevPageNumber}`
  const prevUrl = {
    href: {
      pathname: baseUrl.href.pathname,
      query: prevPageNumber !== 1 ? {
        ...baseUrl.href.query,
        '0': `${baseUrl.href.query['0']}/strana-${prevPageNumber}`
      } : baseUrl.href.query
    },
    as: `${baseUrl.as}${prevPageAs}${textFilter ? `?text-filter=${textFilter}` : ''}`
  }

  const nextPageNumber = currentPage === pageCount ? currentPage : currentPage + 1
  const nextUrl = {
    href: {
      pathname: baseUrl.href.pathname,
      query: {
        ...baseUrl.href.query,
        '0': `${baseUrl.href.query['0']}/strana-${nextPageNumber}`
      }
    },
    as: `${baseUrl.as}/strana-${nextPageNumber}${textFilter ? `?text-filter=${textFilter}` : ''}`
  }

  const pages = []

  for (let i = 1; i <= pageCount; i++) {
    const asPage = i === 1 ? '' : `/strana-${i}`

    pages.push({
      number: i,
      isCurrent: i === currentPage,
      href: {
        pathname: baseUrl.href.pathname,
        query: {
          ...baseUrl.href.query,
          '0': `${baseUrl.href.query['0']}/strana-${i}`
        }
      },
      as: `${baseUrl.as}${asPage}${textFilter ? `?text-filter=${textFilter}` : ''}`
    })
  }

  if (!products?.length) return null

  return (
    <Links
      isFirstPage={isFirstPage}
      isLastPage={isLastPage}
      prevUrl={prevUrl}
      nextUrl={nextUrl}
      pages={[...pages]}
    />
  )
}

const mapStateToProps = (state) => ({
  products: getVehicleParts(state),
  paginationParams: getPagination(state)
})

VehicleParts.propTypes = {
  products: PropTypes.array.isRequired,
  paginationParams: PropTypes.shape({
    page: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
  }).isRequired
}

VehicleParts.defaultProps = {
  products: []
}

export default connect(mapStateToProps)(VehicleParts)
